import React from 'react';

function DashboardComponent() {
  return (
    <div className="dashboard">
      Welcome to the Dashboard!
    </div>
  );
}

export default DashboardComponent;

import React from 'react';
import { FaShieldAlt, FaUserCheck, FaClock, FaDollarSign, FaThumbsUp, FaUsers } from 'react-icons/fa';
import './HomeSectionThree.css';

function StepsCard({ reason, description }) {
    const descriptionPoints = description.map((desc, idx) => (
      <li key={idx}>{desc}</li>
    ));
  
    return (
      <div className="card3">
        <div className="icon">{ICONS_MAP[reason]}</div>
        <h3>{reason}</h3>
        <ul>
          {descriptionPoints}
        </ul>
      </div>
    );
  }
  

// StepsGrid Component
function StepsGrid() {
return (
    <div className="grid">
    {arr.map(item => (
        <StepsCard key={item.id} {...item} />
    ))}
    </div>
);
}

const ICONS_MAP = {
    "Data Security": <FaShieldAlt />,
    "Verified Coordinators and Crews": <FaUserCheck />,
    "Time Efficiency": <FaClock />,
    "Cost-Effectiveness": <FaDollarSign />,
    "Reliability": <FaThumbsUp />,
    "Community and Networking": <FaUsers />
  };

const arr = [
    {
      id: 1,
      reason: "Data Security",
      description: [`Ensuring the security of event-related data is paramount in event management` , `Such a platform employs advanced encryption and authentication methods to protect data.` , `Encryption protocols are in place to prevent unauthorized access to sensitive data`],
    },
    {
      id: 2,
      reason: "Verified Coordinators and Crews",
      description: [`Trustworthy coordinators and crews are fundamental for event success` , `Verified backgrounds and qualifications enhance reliability.` , `Organizers can make informed decisions with verified team information.`],
    },
    {
      id: 3,
      reason: "Time Efficiency",
      description: [`Event organizers face tight schedules and numerous tasks` , `Automated messages and notifications for seamless communication` , `The planning process becomes more efficient and optimized`],
    },
    {
      id: 4,
      reason: "Cost-Effectiveness",
      description: [`Organizers are budget-conscious and seek economical solutions` , `Clear and transparent pricing structures for better financial planning` , `Ability to customize services according to specific event needs.

      `],
    },
    {
      id: 5,
      reason: "Reliability",
      description: [`Ensures smooth functioning during event planning and execution phases.` , `Can adapt to a wide range of event management scenarios.` , `Allows customization to cater to different event types, sizes, and requirements`],
    },
    {
      id: 6,
      reason: "Community and Networking",
      description: [`Organizers connect with experienced professionals` , `Share insights and access valuable resources.` , `Beneficial for learning from industry peers`],
    },
  ];


const HomeSectionThree= () => {
    return (            
        <div className="center">
            <h2 className='center'>Why Choose Us</h2>
            
            <div className="why-choose-us-section">
                <StepsGrid />
            </div>   
        </div>
);
}

export default HomeSectionThree;

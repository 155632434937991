import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FaSignInAlt, FaSignOutAlt, FaUser, FaInfoCircle, FaPhone, FaPlay, FaApple, FaLock } from 'react-icons/fa';

function NavbarComponent({ onNavLinkClick, isLoggedIn, onLogout }) {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="justify-content-between" style={{ width: '100%' }} >
      <Navbar.Brand href="#" onClick={() => onNavLinkClick('Home')} style={{ paddingLeft: '80px'}}>
        <img src="/PocketxtraLogo.png" alt="Company Logo" width="120" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto" style={{ justifyContent: 'flex-end', paddingRight: '80px' }}>
          <Nav.Link onClick={() => onNavLinkClick('Contact Us')}>
            <FaPhone className="mr-2" /> Contact Us
          </Nav.Link>
          <Nav.Link href="https://play.google.com/store/apps/details?id=com.pocketxtra" target="_blank">
            <FaPlay className="mr-2" /> Play Store
          </Nav.Link>
          <Nav.Link href='#' target='_blank'>
            <FaApple className="mr-2" /> Apple Store
          </Nav.Link>
          <Nav.Link onClick={() => onNavLinkClick('Privacy')}>
            <FaLock className="mr-2" /> Privacy
          </Nav.Link>
          
          { 
            isLoggedIn 
            ? <Nav.Link onClick={onLogout}>
                <FaSignOutAlt className="mr-2" /> Logout
              </Nav.Link>
            : <Nav.Link onClick={() => onNavLinkClick('Login')}>
                <FaSignInAlt className="mr-2" /> Login
              </Nav.Link> 
          }
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarComponent;

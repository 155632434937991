import React from 'react';
import { FaRocket, FaSync, FaCheckCircle } from 'react-icons/fa';
import './HomeSectionTwo.css';

const HomeSectionTwo = () => {
    const sections = [
        {
            title: "Crew/Individual Member:",
            icon: <FaRocket size={50} />,
            description: [
                "Individual Workers: Crew members are independent individuals seeking job opportunities within various events. They play a vital role in the successful execution of these events by fulfilling specific positions or tasks.",
                "Job Application: Crew members can browse and apply for job positions listed within the categories of the Pocketxtra platform. Categories could be related to events such as activations, corporate meetings, comedy shows, concerts, sports events, and more.",
                "Crew members can also select a coordinator's handler name if they wish to work with a specific coordinator. If they don't have a preferred coordinator, they can choose Pocketxtra as their default coordinator.",
                "Application Approval: The approval process is comprehensive. Organizers and coordinators review the applications. If qualifications match, the crew member is approved for the event. Otherwise, they may be declined or put on a waiting list.",
                "Attendance Verification: Dual approval process from both event organizers and coordinators ensures accurate attendance."
            ]
        },
        {
            title: "Coordinator/Contributor",
            icon: <FaSync size={50} />,
            description: [
                "Coordinators ensure smooth event operations by managing and overseeing crew members.",
                "They coordinate the activities of crew members for efficiency.",
                "Location-based coordination is used for assigning tasks based on crew members' proximity to the event.",
                "Coordinators also communicate with crew members, providing essential updates and support.",
                "Random Assignment: If crew members don't have a preferred coordinator, the system can assign them one randomly."
            ]
        },
        {
            title: "Organiser",
            icon: <FaCheckCircle size={50} />,
            description: [
                "Organisers initiate and set up events, defining all essential details.",
                "They assign roles and responsibilities for the event.",
                "Organisers review crew applications and decide on approvals.",
                "Attendance approvals, feedback provision, task delegation, and financial management are also part of an organiser's responsibilities.",
                "Being the primary point of communication, organisers ensure efficient coordination and execution of the event."
            ]
        }
    ];

    return (
        <div className="how-it-works-section">
            {sections.map((section, index) => (
                <InfoCard key={index} {...section} />
            ))}
        </div>
    );
}

const InfoCard = ({ icon, title, description }) => {
    return (
        <div className="card2">
            {icon}
            <h3>{title}</h3>
            <ul>
                {description.map((desc, index) => (
                    <li key={index}>{desc}</li>
                ))}
            </ul>
        </div>
    );
}

export default HomeSectionTwo;

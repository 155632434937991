import "./HomeSectionOne.css"
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles


const HomeSectionOne = () => {
    return (
        <div className="container">

        <div className="carousel-column">
            <Carousel 
                showThumbs={false} 
                showStatus={false} 
                infiniteLoop={true} 
                autoPlay={true}
                interval={2000} // Adjust this for desired transition speed
            >

                <div>
                    <img src="/Image04.jpg" alt="Image 2" />
                </div>
                <div>
                    <img src="/Image06.jpg" alt="Image 2" />
                </div>
                <div>
                    <img src="/Image07.jpg" alt="Image 2" />
                </div>
                <div>
                    <img src="/Image08.jpg" alt="Image 2" />
                </div>
                <div>
                    <img src="/Image09.jpg" alt="Image 2" />
                </div>

                
            </Carousel>
        </div>
        <div className="column">
            <h1>What is Pocketxtra?</h1>
            <p><b>Pocketxtra</b> is a platform that offers users the opportunity to earn extra income through short-term jobs 
            in their areas of expertise while also enabling them to create, manage, and coordinate events as <b>Organiserr</b> or <b>Contributers</b>. 
            This dual functionality provides users with flexibility and diverse income-earning opportunities, 
            making it a valuable tool for those looking to supplement their income or engage in event management.</p>
            <p>Pocketxtra is also a platform forr those who wants to manage the workforce by including the Governance such as <b>Nominations, Digital Attendance, Real-Time Feedback, Summary Report & Notifications</b>.
            </p>
        </div>
    </div>
);
}

export default HomeSectionOne;

import './App.css'
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from './components/NavbarComponent';
import ContentContainer from './components/ContentContainer';
import LoginComponent from './components/LoginComponent';
import DashboardComponent from './components/DashboardComponent';
import PrivacyComponent from './components/PrivacyComponent';
import HomeComponent from './components/HomeComponent';
import ContactUs from './components/ContactUs';

function App() {
  const [content, setContent] = useState(<HomeComponent/>);
  const [isLoggedIn, setIsLoggedIn] = useState(false);  // New state

  const handleSuccessfulLogin = () => {
    setIsLoggedIn(true);  // Update the login status
    setContent(<DashboardComponent />);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);  // Reset the login status
    setContent('');  // Clear content or show some homepage
  };

  const handleNavLinkClick = (navContent) => {
    switch(navContent) {
      case 'Login':
        setContent(<LoginComponent onSuccessfulLogin={handleSuccessfulLogin} />);
        break;
      case 'Privacy': 
        setContent(<PrivacyComponent />); // Add this case
        break;
      case 'Home':
          setContent(<HomeComponent />);
          break;
      case 'Contact Us':
          setContent(<ContactUs />);
          break;
      default:
        setContent(navContent);
    }
  };

  return (
    <div>
      <NavbarComponent 
        onNavLinkClick={handleNavLinkClick} 
        isLoggedIn={isLoggedIn} 
        onLogout={handleLogout}
      />
      <ContentContainer content={content} />
    </div>
  );
}

export default App;

import React from 'react';
import HomeSectionOne from './HomeSectionOne';
import HomeSectionTwo from './HomeSectionTwo';
import HomeSectionThree from './HomeSectionThree';

function HomeComponent() {
  return (
    <div>      
      <HomeSectionOne/>
       <hr/>
      <HomeSectionTwo/>
      <hr/>
      <HomeSectionThree/>
   </div>
  );
}

export default HomeComponent;

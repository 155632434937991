import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { FaSignInAlt } from 'react-icons/fa';

function LoginComponent({ onSuccessfulLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [validationError, setValidationError] = useState('');  // state for validation errors

  const handleLogin = () => {
    if (!username || !password) {
      setValidationError('Both username and password are required.');
      return;
    }

    if (username === 'admin' && password === 'admin123') {
      setValidationError(''); // Clear any validation errors
      setLoginError(false);
      onSuccessfulLogin();
    } else {
      setValidationError(''); // Clear any validation errors
      setLoginError(true);
    }
  };

  return (
    <div className="background">
      <div className="box login-box">
        <h2>Pocketxtra Dashboard</h2>
        {loginError && <Alert variant="danger">Login failed. Please check your credentials.</Alert>}
        {validationError && <Alert variant="warning">{validationError}</Alert>}
        <Form>
          <Form.Group controlId="formUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control 
              type="text"
              placeholder="Enter username" 
              value={username} 
              onChange={e => setUsername(e.target.value)} 
            />
          </Form.Group>

          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control 
              type="password" 
              placeholder="Password" 
              value={password} 
              onChange={e => setPassword(e.target.value)} 
            />
          </Form.Group>

          <Form.Group controlId="formRememberMe">
            <Form.Check 
              type="checkbox" 
              label="Remember Me" 
              checked={rememberMe} 
              onChange={e => setRememberMe(e.target.checked)} 
            />
          </Form.Group>

          <Button variant="primary" onClick={handleLogin}>
            <FaSignInAlt className="mr-2"/>
            &nbsp;&nbsp;Login
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default LoginComponent;

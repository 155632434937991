import React from 'react';

function ContentContainer({ content }) {
  return (
    <div style={{ padding: '0.5px 0.1px' }}>
      {content}
    </div>
  );
}

export default ContentContainer;

import React from 'react';

function PrivacyComponent() {
  const currentDate = new Date().toLocaleDateString(); 

  return (
    <div className="background">
    <div className="box privacy-box">
      <h2>Privacy Policy</h2>
      <p>Date: {currentDate}</p>
      <p>Welcome to Pocketxtra. At Pocketxtra, we are committed to protecting your privacy and handling your personal information with utmost care. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you access and use our website.</p>

      <h3>1. Information We Collect</h3>
      <p>We may collect the following types of personal information when you interact with our website:</p>
      <ul>
        <li>Information you provide voluntarily: This includes your name, email address, and any other information you provide through contact forms, registration processes, surveys, or other interactions with our website.</li>
        <li>Automatically collected information: When you visit our website, we may automatically collect certain information from your device, such as your IP address, browser type, operating system, referring URLs, and usage patterns through cookies and similar tracking technologies.</li>
      </ul>

      <h3>2. Use of Personal Information</h3>
      <p>We may use the personal information we collect for the following purposes:</p>
      <ul>
        <li>To provide and improve our website and services.</li>
        <li>To communicate with you and respond to your inquiries or requests.</li>
        <li>To send you marketing and promotional materials (with your consent, where required).</li>
        <li>To personalize your experience on our website.</li>
        <li>To comply with applicable legal obligations.</li>
      </ul>

      <h3>3. Sharing of Personal Information</h3>
      <p>We may share your personal information with the following parties:</p>
      <ul>
        <li>Service Providers: We may share your information with trusted third-party service providers who assist us in operating our website and providing services to you.</li>
        <li>Legal Compliance: We may disclose your information to comply with legal obligations, enforce our terms and conditions, or protect our rights, privacy, safety, or property, as required by law.</li>
      </ul>

      <h3>4. Your Choices and Rights</h3>
      <p>You have the right to:</p>
      <ul>
        <li>Access the personal information we hold about you.</li>
        <li>Correct any inaccurate or incomplete personal information.</li>
        <li>Withdraw your consent for marketing communications (if applicable).</li>
        <li>Delete your personal information, subject to legal and legitimate business requirements.</li>
      </ul>

      <h3>5. Security of Personal Information</h3>
      <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, or alteration. However, please understand that no method of data transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

      <h3>6. Changes to this Privacy Policy</h3>
      <p>We may update this Privacy Policy periodically to reflect changes in our data practices or legal requirements. We encourage you to review this page regularly to stay informed about how we are protecting your personal information.</p>

      <h3>7. Contact Us</h3>
      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at admin@pocketxtra.com</p>
    </div>
    </div>
  );
}

export default PrivacyComponent;

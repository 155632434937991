import React , { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';

function ContactUs() {
    const [name, setName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [message, setMessage] = useState('');

  const isValidEmail = email => {
    // Using a simple regex pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleSubmit = () => {
    if (!name || !userEmail || !message) {
      alert('Please fill all fields.');
      return;
    }

    if (!isValidEmail(userEmail)) {
      alert('Please enter a valid email.');
      return;
    }
};

return (

  <div className="background">
    <div className="box login-box">
      <h2>Get In Touch</h2>
      <div className="contact-form">
        <form action='https://formspree.io/f/mjvqjkjp' method='POST'>
          <div className="form-group" style={{ paddingBottom: '20px' }}>
            <input type="text" name='name' className="form-control" placeholder="Your Name" required />
          </div>
          <div className="form-group" style={{ paddingBottom: '20px' }}>
            <input type="email" name='email' className="form-control" placeholder="Your Email" required />
          </div>
          <div className="form-group" style={{ paddingBottom: '20px' }}>
            <textarea className="form-control" name='message'  rows="5" placeholder="Your Message" required></textarea>
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>


     
  );
};

export default ContactUs;
